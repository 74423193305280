import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RecipeContext } from "./components/recipe/providers/RecipeProvider";
import AppRoutes from "./AppRoutes";
import MainMenu from "./components/menuOptions/MainMenu";
import React, { useContext, useEffect, useState } from "react";

const App = () => {
  const { recipes, badges, kitchenTools, ingredients, featureToggles } =
    useContext(RecipeContext);
  const [currentRecipes, setCurrentRecipes] = useState([]);
  const [isListView, setListView] = useState(true);

  useEffect(() => {
    setCurrentRecipes(recipes);
  }, [recipes]);

  const filteredRecipes = (filteredRecipes) => {
    setCurrentRecipes(filteredRecipes);
  };

  const updateView = (isListView) => {
    setListView(isListView);
  };

  return (
    <div className="App">
      <MainMenu
        recipes={recipes}
        onFilter={filteredRecipes}
        badges={badges}
        isListView={isListView}
      />
      <section id="Section-List">
        <AppRoutes
          recipes={currentRecipes}
          badges={badges}
          kitchenTools={kitchenTools}
          ingredients={ingredients}
          isListView={isListView}
          featureToggles={featureToggles}
          onUpdate={updateView}
        />
        <h6 className="Small-Font Justify-Center Green">
          As an Amazon Associate, I earn from qualifying purchases.
        </h6>
      </section>
    </div>
  );
};

export default App;
