import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";

const RecipeFilteredBadgeCard = (props) => {
  const [badge, setBadge] = useState([]);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    setBadge(props.badge);
    setChecked(
      props.badge.isSelected !== undefined ? props.badge.isSelected : true
    );
  }, [props]);

  const handleChecked = () => {
    setChecked(!checked);
    props.onChecked({
      id: badge.id,
      name: badge.name,
      isSelected: !checked,
      image: badge.image,
    });
  };

  return (
    <button className="Row" onClick={handleChecked}>
      <FontAwesomeIcon
        className="Small-Badge Badge-Icon"
        icon={checked ? faCheckSquare : faSquare}
      />
      <i className="fa-light fa-square-check"></i>
      <img className="Small-Badge marg-25" src={badge.image} />
      <span>{badge.name}</span>
    </button>
  );
};

export default RecipeFilteredBadgeCard;
