import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import React from "react";
import RecipeBadges from "./RecipeBadges";

const RecipeBadgeDetails = (props) => {
  const [badgesOpen, setBadgesOpen] = useState(false);

  const handleBadgesToggle = () => {
    setBadgesOpen(!badgesOpen);
  };

  return (
    <>
      <section className="Background-LGreen">
        <div className="Badge-Title Row Space-B">
          <h4 className="">Badges</h4>
          <button className="Dark-Gray" onClick={handleBadgesToggle}>
            <FontAwesomeIcon icon={badgesOpen ? faArrowUp : faArrowDown} />
          </button>
        </div>
        <div
          className={badgesOpen ? "Row Space-E p1 Div-Parent-Badge" : "Hidden"}
        >
          {badgesOpen && (
            <>
              {props.badges.map((badge, index) => {
                return (
                  <RecipeBadges
                    key={index + "badge"}
                    badge={badge}
                    header={false}
                  />
                );
              })}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default RecipeBadgeDetails;
