import React from "react";
import { useEffect, useState } from "react";

const RecipeIngredientLinkCard = (ingredient) => {
  const [recipeIngredient, setRecipeIngredient] = useState(
    ingredient.ingredient
  );

  useEffect(() => {
    setRecipeIngredient(ingredient.ingredient);
  }, []);

  return (
    <div className="marg-5 link-card-bkgrd">
      <div key={recipeIngredient.id} className="Column kitchen-div">
        <h6 className="Justify-Center kitchen-tool-name">
          {recipeIngredient.name}
        </h6>
        <iframe
          src={recipeIngredient.amazonLink?.src}
          sandbox={recipeIngredient.amazonLink?.sandbox}
          className="kitchen-tool"
        ></iframe>
      </div>
    </div>
  );
};

export default RecipeIngredientLinkCard;
