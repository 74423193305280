import React from "react";

const RecipeKitchenTools = (tool) => {
  let kitchenTool = tool.kitchenTool;
  let typeOf = kitchenTool.typeOf;
  let originalTypeOf = kitchenTool.typeOf;
  if (kitchenTool.typeOf) {
    switch (typeOf) {
      case "1/4":
        typeOf = <span>&frac14;</span>;
        break;
      case "1/3":
        typeOf = <span>&‌#8531;</span>;
        break;
      case "1/2":
        typeOf = <span>&frac12;</span>;
        break;
      case "2/3":
        typeOf = <span>&‌#8532;</span>;
        break;
      case "3/4":
        typeOf = <span>&frac34;</span>;
        break;
      default:
        typeOf = originalTypeOf;
        break;
    }
  }

  return (
    <>
      <div className="Row Medium-Font">
        <p>-</p>
        <div className="Row Space-B Width-100">
          <p className="marg-0 marg-left-5">
            {typeOf} {kitchenTool.name}
            {kitchenTool.quantity > 1 ? "s" : ""}:{" "}
          </p>
          <p>{kitchenTool.quantity} </p>
        </div>
      </div>
    </>
  );
};

export default RecipeKitchenTools;
