import "./Recipe.css";
import loadingLogo from "../../images/BlogFreeRecipeLogo.png";
import React, { useEffect, useState } from "react";
import RecipeCard from "./RecipeCard";

const RecipeList = (props) => {
  const [badges, setBadges] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [kitchenTools, setKitchenTools] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [isListView, setIsListView] = useState(true);
  const [featureToggles, setFeatureToggles] = useState([]);

  useEffect(() => {
    setBadges(props.badges);
    setIngredients(props.ingredients);
    setKitchenTools(props.kitchenTools);
    setRecipes(props.recipe);
    setFeatureToggles(props.featureToggles);
    if (recipes.length > 0) setIsLoading(false);
    setIsListView(true);
    props.onUpdate(isListView);
    props.onRenderPin();
  }, [props.recipe, props.badges, props.kitchenTools, props.ingredients]);

  let amazonLinkStatus = featureToggles.find((f) =>
    f.name.includes("Amazon")
  )?.isEnabled;

  return (
    <>
      {amazonLinkStatus && (
        <div className="marg-t-3 Justify-Center">
          <iframe
            src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=42&l=ur1&category=kitchen&banner=09RE3AASKRA6TA2H9K82&f=ifr&linkID=fcf06002557fde8c7826d4f6b2d6cb16&t=blogfreerecip-20&tracking_id=blogfreerecip-20"
            width="234"
            height="60"
            border="0"
            sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
          ></iframe>
        </div>
      )}
      <h2 className="Sub-Heading Justify-Center marg-t-1">
        For those who ...<span className="TLDR">TLDR</span>
      </h2>
      <div className="m1-5">
        {isLoading ? (
          <div className="Justify-Content" id="Logo">
            <img
              className="loading Justify-Center"
              src={loadingLogo}
              alt="spinning logo"
              id="Logo"
            />
          </div>
        ) : (
          <>
            {recipes?.map((recipe, index) => {
              let recipeBadges = [];
              recipe.badges.map((badge) => {
                badges.map((b) => {
                  if (b.name === badge) {
                    recipeBadges.push(b);
                  }
                });
              });
              let currentRecipeKitchenTools = [];
              recipe.kitchenTools.map((rkt) => {
                kitchenTools.map((kt) => {
                  if (
                    kt.name === rkt.name &&
                    !currentRecipeKitchenTools.includes(kt)
                  )
                    currentRecipeKitchenTools.push(kt);
                });
              });
              let recipeIngredients = [];
              recipe.ingredients.map((ingredient) => {
                ingredients.map((i) => {
                  if (
                    ingredient.name.toLowerCase() === i.name.toLowerCase() &&
                    !recipeIngredients.includes(i)
                  )
                    recipeIngredients.push(i);
                });
              });
              if (
                recipeBadges.length > 0 &&
                currentRecipeKitchenTools.length > 0
              ) {
                if (index % 5 == 0 && index !== 0 && amazonLinkStatus) {
                  return (
                    <div key={recipe.id}>
                      <div className="Justify-Center A-Center marg-b-1">
                        <iframe
                          src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=22&l=ur1&category=kitchen&banner=0YPWCZ4K9ZG2ASK6D082&f=ifr&linkID=44feb79a162533c53a0a0bbf346f9f54&t=blogfreerecip-20&tracking_id=blogfreerecip-20"
                          width="250"
                          height="250"
                          border="0"
                          sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation"
                        ></iframe>
                      </div>
                      <RecipeCard
                        recipe={recipe}
                        badges={recipeBadges}
                        kitchenTools={currentRecipeKitchenTools}
                        ingredients={recipeIngredients}
                      />
                    </div>
                  );
                } else
                  return (
                    <RecipeCard
                      key={recipe.id}
                      amazonLinkStatus={amazonLinkStatus}
                      badges={recipeBadges}
                      ingredients={recipeIngredients}
                      kitchenTools={currentRecipeKitchenTools}
                      recipe={recipe}
                    />
                  );
              }
            })}
          </>
        )}
      </div>
    </>
  );
};

export default RecipeList;
