import React, { useEffect, useState } from "react";
import RecipeFilteredBadgeCard from "./RecipeFilteredBadgeCard";

const RecipeFilterByBadge = (props) => {
  const [badges, setBadges] = useState([]);
  const [allChecked, setAllChecked] = useState(true);
  const [allRecipes, setAllRecipes] = useState([]);
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    setRecipes(props.recipes);
    //add and default isSelected property on every badge to be true
    setAllRecipes(props.recipes);
    for (let b of props.badges) {
      b.isSelected = true;
    }
    //set all badges alphabetically
    setBadges(props.badges.sort((a, b) => a.name.localeCompare(b.name)));
    //When user opens menu, reset recipes to include all recipes
    props.onFilterByBadge(props.recipes);
  }, []);

  const handleClickAll = () => {
    if (allChecked) {
      props.onFilterByBadge([]);
      badges.map((b) => (b.isSelected = false));
      setBadges(badges);
    } else {
      props.onFilterByBadge(allRecipes);
      badges.map((b) => (b.isSelected = true));
      setBadges(badges);
    }
    setAllChecked(!allChecked);
    props.onFilter();
  };

  const getSelectedBadges = (badge) => {
    let handledBadges = [...badges];
    handledBadges.map((b) => {
      if (b.name === badge.name) b.isSelected = badge.isSelected;
    });
    return handledBadges.filter((b) => b.isSelected);
  };

  const getBadgeNames = (badges) => {
    let badgeNames = [];
    for (let badge of badges) {
      badgeNames.push(badge.name);
    }
    return badgeNames;
  };

  const handleChecked = (badge) => {
    //get a list of all badges that are selected
    let selectedBadgeList = getSelectedBadges(badge);
    //make a new list that only includes the name of each selected badge
    let selectedBadgeNames = getBadgeNames(selectedBadgeList);
    //make copy of recipes
    let filteredRecipes = [...recipes];
    //filter through the recipes to include those recipes that have badges in the selected badge name list,
    props.onFilterByBadge(
      filteredRecipes.filter((r) =>
        r.badges.some((b) => selectedBadgeNames.includes(b))
      )
    );
    props.onFilter();
  };

  return (
    <>
      <div className="Column link-card-bkgrd p-1 Width-100 Box-Shadow">
        <button onClick={handleClickAll} className={"Green"}>
          <h5>{allChecked ? "Uncheck All" : "Check All"}</h5>
        </button>
        <div className="Column Filter-Max-Height">
          {badges.map((badge) => {
            return (
              <RecipeFilteredBadgeCard
                badge={badge}
                className="p-1 Row"
                key={badge.id}
                onChecked={handleChecked}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RecipeFilterByBadge;
