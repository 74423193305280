import React from "react";
import RecipeList from "./components/recipe/RecipeList";
import RecipeDetails from "./components/recipe/RecipeDetails";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const AppRoutes = (props) => {
  const updateView = (isListView) => {
    props.onUpdate(isListView);
  };

  const renderPinItBtn = () => {
    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.dataset.pinBuild = "doBuild";
    script.src = "//assets.pinterest.com/js/pinit.js";
    document.body.appendChild(script);
    if (window.doBuild) window.doBuild();
    if (window.parsePinBtns)
      window.parsePinBtns(document.getElementById("pin-it-buttons"));
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RecipeList
              badges={props.badges}
              featureToggles={props.featureToggles}
              ingredients={props.ingredients}
              isListView={props.isListView}
              kitchenTools={props.kitchenTools}
              onRenderPin={renderPinItBtn}
              onUpdate={updateView}
              recipe={props.recipes}
            />
          }
        />
        <Route
          path="recipeDetails/:id"
          element={
            <RecipeDetails
              badges={props.badges}
              featureToggles={props.featureToggles}
              ingredients={props.ingredients}
              isListView={props.isListView}
              kitchenTools={props.kitchenTools}
              onRenderPin={renderPinItBtn}
              onUpdate={updateView}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
