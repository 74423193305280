import DonutChart from "react-donut-chart";
import React, { useEffect, useState } from "react";
import RecipeMacros from "./RecipeMacros";

const RecipeServingInfo = (props) => {
  const [macros, setMacros] = useState([]);
  const [recipe, setRecipe] = useState({});

  useEffect(() => {
    setMacros(props.recipe.macros);
    setRecipe(props.recipe);
  }, [props.recipe]);

  let protein = macros[0];
  let fat = macros[1];
  let carbs = macros[2];

  const reactDonutChartdata = [
    {
      label: protein?.label,
      value: protein?.percentage,
      color: protein?.color,
    },
    {
      label: fat?.label,
      value: fat?.percentage,
      color: fat?.color,
    },
    {
      label: carbs?.label,
      value: carbs?.percentage,
      color: carbs?.color,
    },
  ];
  const reactDonutChartBackgroundColor = ["#212f37", "#2b898b", "#f7b736"];

  return (
    <>
      <section>
        <div className="Row Space-E">
          <div className="Column A-Center">
            <h6>Serving Size</h6>
            <p>{recipe?.servingSize}</p>
          </div>
          <div className="Column A-Center">
            <h6>Total Servings</h6>
            <p>{recipe?.numOfServings}</p>
          </div>
        </div>
      </section>

      <section>
        <div className="Row Space-E marg-t-1">
          <div className="Column A-Center">
            <h6>Calories</h6>
            <p>{recipe?.calories}</p>
          </div>
          <div className="Column A-Center">
            <h6>Macros</h6>
            <div className="Row">
              <div className="Column marg-left-5">
                <DonutChart
                  width={50}
                  height={50}
                  strokeColor="#FFFFFF"
                  data={reactDonutChartdata}
                  colors={reactDonutChartBackgroundColor}
                  innerRadius={0.7}
                  selectedOffset={0.4}
                  legend={false}
                  interactive={false}
                />
              </div>
              <div className="Column marg-left-5">
                {macros?.map((macro) => {
                  return <RecipeMacros key={macro?.label} macro={macro} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecipeServingInfo;
