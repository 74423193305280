import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Base.css";

const BaseAccordionItem = (props) => {
  const [eventKey, setEventKey] = useState(0);
  const [expandedContent, setExpandedContent] = useState([]);
  const [expandedItem, setExpandedItem] = useState("");
  const [headerText, setHeaderText] = useState("");
  let accordionBodyKey = 1;

  useEffect(() => {
    setEventKey(props.eventKey);
    setExpandedContent(props.expandedContent);
    setExpandedItem(props.expandedItem);
    setHeaderText(props.headerText);
  }, [props]);

  const toggleArrow = () => {
    props.onExpandItem(headerText == expandedItem ? "" : headerText);
  };

  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header onClick={toggleArrow}>
        <div className="Row">
          <h5 className="Green">{headerText}</h5>
          <FontAwesomeIcon
            className="accordion-button-override"
            icon={headerText == expandedItem ? faArrowUp : faArrowDown}
          />
        </div>
      </Accordion.Header>
      <Accordion.Body
        className={headerText == "Sort" ? "accordion-body-sort" : ""}
      >
        {expandedContent.map((ec) => {
          return ec.map((ecItem) => {
            return (
              <div key={accordionBodyKey++ + headerText} className={headerText == 'Search' ? 'marg-b-1' : ''}>
                {ecItem.component}
              </div>
            );
          });
        })}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default BaseAccordionItem;
