import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const RecipeSearchByIngredientIncluded = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [buttonWidth, setButtonWidth] = useState(0);

  const handleChange = (e) => {
    setSearchInput(e.target.value);
    let filteredRecipes = props.recipes.filter((r) =>
      r.ingredients.some((i) =>
        i.name.toLowerCase().includes(searchInput.toLowerCase())
      )
    );
    props.onSearch(filteredRecipes);
    props.onSearched();
  };

  const clearSearch = () => {
    setSearchInput("");
    props.onSearch(props.recipes);
  };

  useEffect(() => {
    if (buttonWidth === 0) {
      setButtonWidth(document.getElementById("searchIcon")?.offsetWidth);
    }
  }, [buttonWidth]);

  return (
    <>
      <div>
        <input
          id="Recipe-Search-By-Ingredient"
          style={{ marginLeft: buttonWidth }}
          className="Search-By-Name"
          type="search"
          placeholder="Search by included ingredient"
          value={searchInput}
          onChange={handleChange}
        />
        <button
          id="searchIcon"
          className="faking"
          onClick={searchInput === "" ? () => {} : clearSearch}
        >
          <FontAwesomeIcon
            className="fa-thin"
            icon={searchInput === "" ? faMagnifyingGlass : faXmark}
          />
        </button>
      </div>
    </>
  );
};

export default RecipeSearchByIngredientIncluded;
