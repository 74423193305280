import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import logo from "../../images/BlogFreeRecipe-drkBkgrd.png";
import PageDivider from "../../base/PageDivider";
import React, { useEffect, useState } from "react";
import RecipeBadgeDetails from "./RecipeBadgeDetails";
import RecipeCollapsed from "./RecipeCollapsed";
import RecipeIngredientToolTab from "./RecipeIngredientToolTab";
import RecipeKitchenToolLinks from "./RecipeKitchenToolLinks";
import RecipeServingInfo from "./RecipeServingInfo";
import RecipeStepList from "./RecipeListStep";
import RecipeTiming from "./RecipeTiming";
import RecipeIngredientLinks from "./RecipeIngredientLinks";

const RecipeCard = (props) => {
  const [badges, setBadges] = useState([]);
  const [currentStep, setCurrentStep] = useState(props.recipe.steps[0]);
  const [ingredients, setIngredients] = useState([]);
  const [amazonLinkStatus, setAmazonLinkStatus] = useState(false);
  const [kitchenTools, setKitchenTools] = useState([]);
  const [mainPicture, setMainPicture] = useState(logo);
  const [recipe, setRecipe] = useState({});
  const [toggleCard, setToggledCard] = useState(true);
  const [toggleDirectionFormat, setToggleDirectionFormat] = useState(true);

  useEffect(() => {
    setAmazonLinkStatus(props.amazonLinkStatus);
    setBadges(props.badges);
    setIngredients(props.ingredients);
    setKitchenTools(props.kitchenTools);
    setRecipe(props.recipe);
  }, [props.recipe, props.badges, props.kitchenTools, props.ingredients]);

  useState(() => {
    if (Object.keys(recipe).length !== 0) {
      setCurrentStep(recipe.steps[0]);
    }
    if (props.recipe.mainPicture !== null && props.recipe.mainPicture !== "") {
      setMainPicture(props.recipe.mainPicture);
    }
    if (
      (props.recipe.mainPicture === null || props.recipe.mainPicture === "") &&
      props.recipe.additionalPictures.length > 0
    ) {
      setMainPicture(props.recipe.additionalPictures[0]);
    }
  }, [recipe, props]);

  const nextStep = () => {
    if (currentStep.id.split("-")[0] < recipe.steps.length) {
      let newStep = parseInt(currentStep.id?.split("-")[0]) + 1;
      setCurrentStep(recipe.steps[newStep - 1]);
    }
  };

  const prevStep = () => {
    if (currentStep.id.split("-")[0] > 1) {
      let newStep = parseInt(currentStep.id.split("-")[0]) - 1;
      setCurrentStep(recipe.steps[newStep - 1]);
    }
  };

  const handleToggle = () => {
    setToggledCard(!toggleCard);
  };

  const handleDirectionsFormatToggle = () => {
    setToggleDirectionFormat(!toggleDirectionFormat);
  };

  const onItemNeeded = (e) => {
    let itemLocation = "#" + e.split(" ").pop();
    window.location.href = `${itemLocation}`;
  };

  return (
    <>
      {Object.keys(recipe).length !== 0 ? (
        <>
          <div className="Recipe-Card p1 marg-b-1">
            {toggleCard ? (
              <div className="Row Space-B">
                <RecipeCollapsed recipe={recipe} badges={badges} />
                <button className="Dark-Gray" onClick={handleToggle}>
                  <FontAwesomeIcon icon={faArrowDown} />
                </button>
              </div>
            ) : (
              <div id="top">
                <div className="Row marg-1 Space-B">
                  <Link to={`recipeDetails/${recipe.id}`}>
                    <h2 className="Width-16">{recipe.recipeName}</h2>{" "}
                  </Link>
                  <button
                    className="Dark-Gray Relative-Pos"
                    onClick={handleToggle}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </button>
                </div>
                <div className="Justify-Center">
                  <img
                    alt="small main"
                    src={mainPicture}
                    className="Large-Logo"
                  />
                </div>
                <RecipeTiming recipe={recipe} />
                <PageDivider />
                <div className="p1">
                  <RecipeServingInfo recipe={recipe} />
                  <RecipeBadgeDetails badges={badges} />
                </div>
                <PageDivider />
                <RecipeIngredientToolTab
                  recipe={recipe}
                  itemNeeded={onItemNeeded}
                />
                <PageDivider />
                <section>
                  <h4 className="p1">Directions</h4>
                  <div className="marg-t-1 Row">
                    <div
                      className="Width-50 Justify-Center"
                      id={
                        toggleDirectionFormat
                          ? "Div-Selected"
                          : "Div-Not-Selected"
                      }
                    >
                      <button onClick={handleDirectionsFormatToggle}>
                        <p
                          className={
                            toggleDirectionFormat
                              ? "Selected marg-0"
                              : "Not-Selected marg-0"
                          }
                        >
                          List View
                        </p>
                      </button>
                    </div>
                    <div
                      className="Width-50 Justify-Center"
                      id={
                        !toggleDirectionFormat
                          ? "Div-Selected"
                          : "Div-Not-Selected"
                      }
                    >
                      <button onClick={handleDirectionsFormatToggle}>
                        <p
                          className={
                            !toggleDirectionFormat
                              ? "Selected marg-0"
                              : "Not-Selected marg-0"
                          }
                        >
                          Card View
                        </p>
                      </button>
                    </div>
                  </div>
                  <div className="Step">
                    {toggleDirectionFormat ? (
                      <>
                        <div className="p1 marg-5">
                          {recipe?.steps.map((step, index) => {
                            return (
                              <RecipeStepList
                                key={index + "step-list"}
                                step={step}
                              />
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="pt-1 marg-5 max-h-25 Column">
                          <div className="Justify-Center ">
                            {recipe?.steps.length < 11 ? (
                              <div className="Row marg-t-1 max-h-1 max-w-15">
                                {recipe?.steps.map((step, index) => {
                                  return (
                                    <div key={index + "step-progress-bar"}>
                                      <ul className="p0 Row">
                                        <li
                                          onClick={() => {
                                            setCurrentStep(step);
                                          }}
                                          className={
                                            parseInt(
                                              currentStep.id.split("-")[0]
                                            ) >= parseInt(step.id.split("-")[0])
                                              ? "Done"
                                              : "To-Do"
                                          }
                                        >
                                          {step.id.split("-")[0]}
                                        </li>
                                      </ul>
                                      {step.id.split("-")[0] <
                                        recipe.steps.length && (
                                        <hr
                                          className={
                                            step.id.split("-")[0] >
                                            currentStep.id.split("-")[0] - 1
                                              ? "Progress-Bar-To-Do"
                                              : "Progress-Bar-Done"
                                          }
                                        />
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <div className="marg-t-1 Width-90">
                                <ul id="Progress-Bar-UL Width-100">
                                  {recipe?.steps.map((step, index) => {
                                    return (
                                      <li
                                        key={index + "step-progress-bar"}
                                        onClick={() => {
                                          setCurrentStep(step);
                                        }}
                                        className={
                                          index === 0
                                            ? "First-LI"
                                            : index ===
                                                recipe.steps.length - 1 &&
                                              parseInt(
                                                currentStep.id.split("-")[0]
                                              ) >=
                                                parseInt(step.id.split("-")[0])
                                            ? "Done Last-LI"
                                            : index ===
                                                recipe.steps.length - 1 &&
                                              parseInt(
                                                currentStep.id.split("-")[0]
                                              ) <
                                                parseInt(step.id.split("-")[0])
                                            ? "To-Do Last-LI"
                                            : index !== 0 &&
                                              recipe.steps.length - 1 &&
                                              parseInt(
                                                currentStep.id.split("-")[0]
                                              ) >=
                                                parseInt(step.id.split("-")[0])
                                            ? "Done Block"
                                            : index !== 0 &&
                                              recipe.steps.length - 1 &&
                                              parseInt(
                                                currentStep.id.split("-")[0]
                                              ) <
                                                parseInt(
                                                  step.id.split("-")[0]
                                                ) &&
                                              "To-Do Block"
                                        }
                                        style={{
                                          width:
                                            100 / recipe.steps.length + "%",
                                        }}
                                      ></li>
                                    );
                                  })}
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="Step-Card-Container Justify-Center">
                            {currentStep && (
                              <div className="Justify-Center">
                                <div className="Row Width-25">
                                  <button
                                    className="Arrow-Left-Btn"
                                    onClick={prevStep}
                                  >
                                    <FontAwesomeIcon
                                      className="Arrow-Left"
                                      icon={faArrowDown}
                                    />
                                  </button>
                                  <section className="Step-Card">
                                    <h3 className="Justify-Center marg-1">
                                      Step {currentStep.id.split("-")[0]}
                                    </h3>
                                    <div className="Step-Detail p1">
                                      <p>{currentStep.detail}</p>
                                    </div>
                                  </section>
                                  <button
                                    className="Arrow-Right-Btn"
                                    onClick={nextStep}
                                  >
                                    <FontAwesomeIcon
                                      className="Arrow-Right"
                                      icon={faArrowDown}
                                    />
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </section>
                {amazonLinkStatus && (
                  <>
                    <PageDivider />
                    <section id="Ingredient" className="Linked-Sections">
                      <h4 className="Linked-Titles">Purchase Ingredients</h4>
                      <RecipeIngredientLinks ingredients={ingredients} />
                      <h6 className="Disclaimer-Text">
                        Please note: the ingredients in this section are
                        suggestions, and may not be the exact product used by
                        the recipe's author.
                      </h6>
                    </section>
                    <PageDivider />
                    <section id="Tool" className="Linked-Sections">
                      <h4 className="Linked-Titles">Purchase Kitchen Tools</h4>
                      <RecipeKitchenToolLinks kitchenTools={kitchenTools} />
                      <h6 className="Disclaimer-Text">
                        Please note: the kitchen tools in this section are
                        suggestions, and may not be the exact product used by
                        the recipe's author.
                      </h6>
                    </section>
                  </>
                )}
                <a href="#top" className="Row marg-1 Justify-Center Green">
                  <h6 className="Small-Font">Top of Recipe</h6>
                </a>
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default RecipeCard;
