import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import BaseAccordionItem from "./BaseAccordionItem";

const BaseAccordionList = (props) => {
  const [accordionItems, setAccordionItems] = useState([]);
  const [filterByItems, setFilterByItems] = useState([]);
  const [expandedItem, setExpandedItem] = useState("");
  let eventKey = 1;

  useEffect(() => {
    setAccordionItems(props.accordionFunctions);
    setFilterByItems(props.filterByItems);
    setExpandedItem(props.expandedItem[0]);
  }, [props]);

  const handleExpand = (headerText) => {
    props.onExpandItem(headerText);
  };

  return (
    <Accordion defaultActiveKey="0">
      {accordionItems.map((item) => {
        return (
          <BaseAccordionItem
            eventKey={eventKey++}
            expandedContent={Object.values(item)}
            expandedItem={expandedItem}
            filterByItems={filterByItems}
            headerText={Object.keys(item)}
            key={Object.keys(item)}
            onExpandItem={handleExpand}
          />
        );
      })}
    </Accordion>
  );
};

export default BaseAccordionList;
