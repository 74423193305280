import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const RecipeMacros = (props) => {
  const [macro, setMacro] = useState({});

  useEffect(() => {
    setMacro(props.macro);
  }, [props]);

  return (
    <>
      <div className="Row">
        <FontAwesomeIcon width={7} color={macro?.color} icon={faCircle} />
        <p className="Small-Font marg-0">
          {macro?.grams}g {macro?.label}
        </p>
      </div>
    </>
  );
};

export default RecipeMacros;
