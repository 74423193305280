import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const PageDivider = () => {
  return (
    <div className="Row">
      <hr className="Divider-Line"></hr>
      <FontAwesomeIcon className="marg-5 Dark-Gray" icon={faUtensils} />
      <hr className="Divider-Line"></hr>
    </div>
  );
};

export default PageDivider;
