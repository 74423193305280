import React from "react";

const RecipeTiming = (props) => {
  return (
    <>
      <section className="Column p1">
        <div className="Row Space-E">
          <div className="Column A-Center">
            <h6>Prep Time</h6>
            <p>{props.recipe.prepTime}</p>
          </div>
          <div className="Column A-Center">
            <h6>Cook Time</h6>
            <p>{props.recipe.cookTime}</p>
          </div>
        </div>
        <div className="Column A-Center">
          <h6>Total Time</h6>
          <p>{props.recipe.totalTime}</p>
        </div>
      </section>
    </>
  );
};

export default RecipeTiming;
