import React, { useEffect, useState } from "react";
import RecipeKitchenToolLinkCard from "./RecipeKitchenToolLinkCard";

const RecipeKitchenToolLinks = (tools) => {
  const [kitchenTools, setKitchenTools] = useState([]);

  useEffect(() => {
    if (!tools.recipeTools) {
      setKitchenTools(tools.kitchenTools);
    }
    if (tools.recipeTools && tools.tools) {
      let currentRecipeKitchenTools = [];
      tools.recipeTools.map((rkt) => {
        tools.tools.map((kt) => {
          if (kt.name === rkt.name && !currentRecipeKitchenTools.includes(kt))
            currentRecipeKitchenTools.push(kt);
        });
      });
      setKitchenTools(currentRecipeKitchenTools);
    }
  }, [tools]);

  return (
    <div className="Row kitchen-tools">
      {kitchenTools.map((tool) => {
        return <RecipeKitchenToolLinkCard key={tool.id} tool={tool} />;
      })}
    </div>
  );
};

export default RecipeKitchenToolLinks;
