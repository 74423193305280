import React from "react";

const RecipeStepList = (step) => {
  return (
    <>
      <div className="Row Medium-Font">
        <p className="Width-1">{step.step.id.split("-")[0]}.</p>
        <span className="marg-0 marg-left-5">{step.step.detail}</span>
      </div>
    </>
  );
};

export default RecipeStepList;
