import BaseAccordionList from "../../base/BaseAccordionList";
import { faSliders, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../images/BlogFreeRecipeLogo.png";
import React, { useState } from "react";
import RecipeSearchByIngredientIncluded from "../recipe/RecipeSearchByIngredientIncluded";
import RecipeSearchByName from "../recipe/RecipeSearchByName";
import RecipeSortAlphabetically from "../recipe/RecipeSortAlphabetically";
import RecipeSortByNumOfSteps from "../recipe/RecipeSortByNumOfSteps";
import RecipeFilterByBadge from "../recipe/RecipeFilterByBadge";

const MainMenu = (props) => {
  const [expandedItem, setExpandedItem] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShowingResultsBtn, setIsShowingResultsBtn] = useState(false);
  const [recipes, setRecipes] = useState([]);
  
  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      setIsShowingResultsBtn(false);
      setExpandedItem("");
    }
  };

  const handleAction = () => {
    setIsShowingResultsBtn(true);
  };

  const handleExpandItem = (clickedItem) => {
    if (expandedItem != clickedItem) setExpandedItem(clickedItem);
    else setExpandedItem("");
  };

  const searchedRecipe = (recipes) => {
    setRecipes(recipes);
    props.onFilter(recipes);
  };

  const alphaSorted = (recipes) => {
    setRecipes(recipes);
    props.onFilter(recipes);
  };

  const stepsSorted = (recipes) => {
    setRecipes(recipes);
    props.onFilter(recipes);
  };

  const filterByBadge = (recipes) => {
    setRecipes(recipes);
    props.onFilter(recipes);
  };

  const allRecipesClicked = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <header className="App-header">
        {!isMenuOpen ? (
          <div className="Row Space-B">
            <div className="Row JC-Left">
              <a href="/" className="Logo-Home-Btn">
                <img
                  src={logo}
                  className="App-logo"
                  id="Menu-Logo"
                  alt="logo"
                />
              </a>
              <h1 className="Menu-Header">Blog Free Recipe</h1>
            </div>
            <button className="Filter" onClick={handleClick}>
              <FontAwesomeIcon className="fa-thin fa-2xl" icon={faSliders} />
            </button>
          </div>
        ) : (
          <div className="App-header-expanded Column">
            <div className="Row Space-B">
              <div className="Row JC-Left">
                <a href="/" className="Logo-Home-Btn">
                  <img
                    src={logo}
                    className="App-logo"
                    id="Menu-Logo"
                    alt="logo"
                  />
                </a>
                <h1 className="Menu-Header">Blog Free Recipe</h1>
              </div>
              <button className="Filter" onClick={handleClick}>
                <FontAwesomeIcon
                  className="fa-thin fa-2xl"
                  icon={faWindowClose}
                />
              </button>
            </div>

            {props.isListView ? (
              <div className="Menu-Options">
                {isShowingResultsBtn ? (
                  <div className="results-btn">
                    <button
                      className={
                        "Green p25p2 bord-15 marg-5 Background-LGreen Box-Shadow"
                      }
                      onClick={handleClick}
                    >
                      Show Results
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                <BaseAccordionList
                  accordionFunctions={[
                    {
                      Search: [
                        {
                          component: (
                            <RecipeSearchByName
                              recipes={props.recipes}
                              onSearch={searchedRecipe}
                              onSearched={handleAction}
                            />
                          ),
                          name: "Recipe Name",
                        },
                        {
                          component: (
                            <RecipeSearchByIngredientIncluded
                              recipes={props.recipes}
                              onSearch={searchedRecipe}
                              onSearched={handleAction}
                            />
                          ),
                          name: "Included Ingredient",
                        },
                      ],
                    },
                    {
                      Filter: [
                        {
                          component: (
                            <RecipeFilterByBadge
                              badges={props.badges}
                              recipes={props.recipes}
                              onFilterByBadge={filterByBadge}
                              onFilter={handleAction}
                            />
                          ),
                          name: "Badge",
                        },
                      ],
                    },
                    {
                      Sort: [
                        {
                          component: (
                            <RecipeSortAlphabetically
                              recipes={props.recipes}
                              onAlphaSort={alphaSorted}
                              onSort={handleAction}
                            />
                          ),
                          name: "Alphabetical",
                        },
                        {
                          component: (
                            <RecipeSortByNumOfSteps
                              recipes={props.recipes}
                              onNumOfStepsSort={stepsSorted}
                              onSort={handleAction}
                            />
                          ),
                          name: "Recipe Steps",
                        },
                      ],
                    },
                  ]}
                  filterByItems={props.badges}
                  expandedItem={expandedItem}
                  onExpandItem={handleExpandItem}
                  recipes={props.recipes}
                />
              </div>
            ) : (
              <>
                <a href="/" className="Justify-Center marg-5">
                  <button
                    className="Green p25p2 bord-15 Background-LGreen"
                    onClick={allRecipesClicked}
                  >
                    All Recipes
                  </button>
                </a>
              </>
            )}
          </div>
        )}
      </header>
    </>
  );
};

export default MainMenu;
