import { useParams } from "react-router-dom";
import * as Realm from "realm-web";
import loadingLogo from "../../images/BlogFreeRecipeLogo.png";
import logo from "../../images/BlogFreeRecipe-drkBkgrd.png";
import PageDivider from "../../base/PageDivider";
import React, { useState, useEffect } from "react";
import RecipeBadgeDetails from "./RecipeBadgeDetails";
import RecipeIngredientLinks from "./RecipeIngredientLinks";
import RecipeIngredientToolTab from "./RecipeIngredientToolTab";
import RecipeKitchenToolLinks from "./RecipeKitchenToolLinks";
import RecipeProgressBar from "./RecipeProgressBar";
import RecipeServingInfo from "./RecipeServingInfo";
import RecipeStepList from "./RecipeListStep";
import RecipeTiming from "./RecipeTiming";

const RecipeDetails = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mainPicture, setMainPicture] = useState(logo);
  const { id } = useParams();
  const [toggleDirectionFormat, setToggleDirectionFormat] = useState(true);
  const [currentBadges, setCurrentBadges] = useState(props.badges);
  const [kitchenTools, setKitchenTools] = useState(props.kitchenTools);
  const [recipeKitchenTools, setRecipeKitchenTools] = useState([]);
  const [ingredients, setIngredients] = useState(props.ingredients);
  const [recipeIngredients, setRecipeIngredients] = useState([]);
  const [isListView, setIsListView] = useState(false);
  const [featureToggles, setFeatureToggles] = useState([]);
  const [recipe, setRecipe] = useState({
    id: "",
    additionalPictures: [],
    badges: [""],
    calories: 0,
    cookTime: "",
    ingredients: [
      {
        id: "1-",
        name: "",
        quantity: "",
        measurement: "",
      },
    ],
    kitchenTools: [
      {
        name: "",
        typeOf: "",
        quantity: 0,
      },
    ],
    macros: [
      {
        label: "Protein",
        percentage: 0,
        grams: 0,
        color: "#212f37",
      },
      {
        label: "Fat",
        percentage: 0,
        grams: 0,
        color: "#2b898b",
      },
      {
        label: "Carbs",
        percentage: 0,
        grams: 0,
        color: "#f7b736",
      },
    ],
    mainPicture: logo,
    numOfServings: 0,
    prepTime: "",
    recipeName: "",
    servingSize: "",
    steps: [
      {
        id: "1-",
        detail: "",
      },
    ],
    totalTime: "",
  });

  useEffect(() => {
    async function fetchData() {
      const realm_app_id = "data-raewy";
      const app = new Realm.App({ id: realm_app_id });
      const credentials = Realm.Credentials.anonymous();

      try {
        const user = await app.logIn(credentials);
        await user.functions
          .getRecipeById(id)
          .then((result) => setRecipe(result));
      } catch (error) {
        console.log("This went wrong: ", error);
      }
    }
    fetchData();
    setIsListView(false);
    props.onUpdate(isListView);
  }, [id]);

  useEffect(() => {
    let badges = [];
    if (recipe.mainPicture !== null && recipe.mainPicture !== "")
      setMainPicture(recipe.mainPicture);
    if (
      (recipe.mainPicture === null || recipe.mainPicture === "") &&
      recipe.additionalPictures.length > 0
    ) {
      setMainPicture(recipe.additionalPictures[0]);
    }

    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.dataset.pinBuild = "doBuild";
    script.src = "//assets.pinterest.com/js/pinit.js";
    document.body.appendChild(script);

    if (
      (recipe.mainPicture !== null || recipe.mainPicture !== "") &&
      recipe.id !== ""
    ) {
      setKitchenTools(props.kitchenTools);
      setRecipeKitchenTools(recipe.kitchenTools);
      setIngredients(props.ingredients);
      setRecipeIngredients(recipe.ingredients);
      setFeatureToggles(props.featureToggles);

      if (window.doBuild) window.doBuild();
      if (window.parsePinBtns)
        window.parsePinBtns(document.getElementById("pin-it-buttons"));
      setIsLoading(false);
    }
    if (props.badges.length > 0) {
      props.badges.map((b) => {
        recipe.badges.map((badge) => {
          if (badge === b.name) {
            badges.push(b);
          }
        });
      });
      setCurrentBadges(badges);
    }
  }, [recipe, props]);

  const handleDirectionsFormatToggle = () => {
    setToggleDirectionFormat(!toggleDirectionFormat);
  };

  const onItemNeeded = (e) => {
    let itemLocation = "#" + e.split(" ").pop();
    window.location.href = `${itemLocation}`;
  };

  let spans = document.querySelectorAll('[class="Float"] > span');

  useEffect(() => {
    if (spans.length > 1) {
      for (let s = 1; s < spans.length; s++) {
        spans[s].remove();
      }
    }
  }, [spans]);

  if (window.doBuild) window.doBuild();
  if (window.parsePinBtns)
    window.parsePinBtns(document.getElementById("pin-it-buttons"));

  let amazonLinkStatus = featureToggles.find((f) =>
    f.name.includes("Amazon")
  )?.isEnabled;

  return (
    <>
      <div id="top" className="p1 Recipe-Details">
        <div className="Float">
          <a
            className="Hide"
            data-pin-do="buttonBookmark"
            data-pin-tall="true"
            data-pin-round="true"
            href="https://www.pinterest.com/pin/create/button/"
          >
            <img src="//assets.pinterest.com/images/pidgets/pinit_fg_en_round_red_32.png" />
          </a>
        </div>

        {isLoading ? (
          <img
            className="loading"
            src={loadingLogo}
            alt="spinning logo"
            id="Logo"
          />
        ) : (
          <>
            <div className="Row marg-1 Space-B">
              <h1>{recipe.recipeName}</h1>
            </div>
            <div className="Justify-Center">
              <img alt="small main" src={mainPicture} className="Large-Logo" />
            </div>
            <RecipeTiming recipe={recipe} />
            <PageDivider />
            <div className="p1">
              <RecipeServingInfo recipe={recipe} />
              <div className="p1">
                <RecipeBadgeDetails badges={currentBadges} />
              </div>
            </div>
            <PageDivider />
            <div className="p1">
              <RecipeIngredientToolTab
                recipe={recipe}
                itemNeeded={onItemNeeded}
              />
            </div>
            <PageDivider />
            <section className="p1">
              <h4 className="p1">Directions</h4>
              <div className="marg-t-1 Row">
                <div
                  className="Width-50 Justify-Center"
                  id={
                    toggleDirectionFormat ? "Div-Selected" : "Div-Not-Selected"
                  }
                >
                  <button onClick={handleDirectionsFormatToggle}>
                    <p
                      className={
                        toggleDirectionFormat
                          ? "Selected marg-0"
                          : "Not-Selected marg-0"
                      }
                    >
                      List View
                    </p>
                  </button>
                </div>
                <div
                  className="Width-50 Justify-Center"
                  id={
                    !toggleDirectionFormat ? "Div-Selected" : "Div-Not-Selected"
                  }
                >
                  <button onClick={handleDirectionsFormatToggle}>
                    <p
                      className={
                        !toggleDirectionFormat
                          ? "Selected marg-0"
                          : "Not-Selected marg-0"
                      }
                    >
                      Card View
                    </p>
                  </button>
                </div>
              </div>
              <div className="Step">
                {toggleDirectionFormat ? (
                  <>
                    <div className="p1 marg-5">
                      {recipe.steps.map((step, index) => {
                        return (
                          <RecipeStepList
                            key={index + "step-list"}
                            step={step}
                          />
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <RecipeProgressBar recipe={recipe} />
                  </>
                )}
              </div>
            </section>
            {amazonLinkStatus && (
              <>
                <PageDivider />
                <section id="Ingredient" className="Linked-Sections">
                  <h4 className="Linked-Titles">Purchase Ingredients</h4>
                  {ingredients?.length > 0 && recipeIngredients.length > 0 && (
                    <RecipeIngredientLinks
                      ingredients={ingredients}
                      recipeIngredients={recipeIngredients}
                    />
                  )}
                  <h6 className="Disclaimer-Text">
                    Please note: the ingredients in this section are
                    suggestions, and may not be the exact product used by the
                    recipe's author.
                  </h6>
                </section>
                <PageDivider />
                <section id="Tool" className="Linked-Sections">
                  <h4 className="Linked-Titles">Purchase Kitchen Tools</h4>
                  {kitchenTools?.length > 0 &&
                    recipeKitchenTools?.length > 0 && (
                      <RecipeKitchenToolLinks
                        tools={kitchenTools}
                        recipeTools={recipeKitchenTools}
                      />
                    )}
                  <h6 className="Disclaimer-Text">
                    Please note: the kitchen tools in this section are
                    suggestions, and may not be the exact product used by the
                    recipe's author.
                  </h6>
                </section>
              </>
            )}
            <section>
              {recipe?.additionalPictures?.map((p, i) => {
                return (
                  <div key={"additionalPic" + i}>
                    <img
                      src={p}
                      alt={"Additional" + recipe.recipeName + "picture"}
                      className="Hidden"
                    />
                  </div>
                );
              })}
            </section>
            <a href="#top" className="Row marg-1 Justify-Center Green">
              <h6 className="Small-Font">Top of Recipe</h6>
            </a>
          </>
        )}
      </div>
    </>
  );
};

export default RecipeDetails;
