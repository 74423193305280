import React from "react";
import { useEffect, useState } from "react";

const RecipeKitchenToolLinkCard = (tool) => {
  const [kitchenTool, setKitchenTool] = useState(tool.tool);

  useEffect(() => {
    setKitchenTool(tool.tool);
  }, []);

  return (
    <div className="marg-5 link-card-bkgrd">
      <div key={kitchenTool.id} className="Column kitchen-div">
        <h6 className="Justify-Center kitchen-tool-name">{kitchenTool.name}</h6>
        <iframe
          src={kitchenTool.amazonLink?.src}
          sandbox={kitchenTool.amazonLink?.sandbox}
          className="kitchen-tool"
        ></iframe>
      </div>
    </div>
  );
};

export default RecipeKitchenToolLinkCard;
