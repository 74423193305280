import { useEffect, useState } from "react";
import React from "react";
import RecipeIngredients from "./RecipeIngredients";
import RecipeKitchenTools from "./RecipeKitchenTools";

const RecipeIngredientToolTab = (props) => {
  const [ingredientKitchenToolTab, setIngredientKitchenToolTab] =
    useState(true);
  const [ingredients, setIngredients] = useState([]);
  const [kitchenTools, setKitchenTools] = useState([]);

  const handleIngredientKitchenToolTabClick = () => {
    setIngredientKitchenToolTab(!ingredientKitchenToolTab);
  };

  const itemNeeded = (e) => {
    props.itemNeeded(e.target.textContent);
  };

  const alphabetizeIngredients = () => {
    let copy = [...ingredients];
    copy.sort((a, b) => a.name.localeCompare(b.name));
    setIngredients(copy);
  };

  const alphabetizeKitchenTools = () => {
    let copy = [...kitchenTools];
    copy.sort((a, b) => a.name.localeCompare(b.name));
    setKitchenTools(copy);
  };

  useEffect(() => {
    setIngredients(props.recipe.ingredients);
    setKitchenTools(props.recipe.kitchenTools);
  }, []);

  return (
    <>
      <section className="p1">
        <div className="Row Space-B">
          <h4
            className={ingredientKitchenToolTab ? "Selected" : "Not-Selected"}
            onClick={handleIngredientKitchenToolTabClick}
          >
            Ingredients
          </h4>
          <h4
            className={ingredientKitchenToolTab ? "Not-Selected" : "Selected"}
            onClick={handleIngredientKitchenToolTabClick}
          >
            {" "}
            Kitchen Tools
          </h4>
        </div>
        {ingredientKitchenToolTab ? (
          <div className="marg-t-1">
            <div className="Justify-Center">
              <button
                className="Green p-1 bord-5 Background-LGreen"
                onClick={alphabetizeIngredients}
              >
                ABC
              </button>
            </div>
            {ingredients.map((ingredient, index) => {
              return (
                <RecipeIngredients
                  key={index + "ingredient"}
                  ingredient={ingredient}
                />
              );
            })}
            <a
              onClick={itemNeeded}
              className="Row marg-1 Justify-Center Green"
              value="ingredient"
            >
              <h6 className="Small-Font">I need an Ingredient</h6>
            </a>
          </div>
        ) : (
          <div className="marg-t-1">
            <div className="Justify-Center">
              <button
                className="Green p-1 bord-5 Background-LGreen"
                onClick={alphabetizeKitchenTools}
              >
                ABC
              </button>
            </div>
            {kitchenTools.map((kitchenTool, index) => {
              return (
                <RecipeKitchenTools
                  key={index + "kitchen-tool"}
                  kitchenTool={kitchenTool}
                />
              );
            })}
            <a onClick={itemNeeded} className="Row marg-1 Justify-Center Green">
              <h6 className="Small-Font">I need a Kitchen Tool</h6>
            </a>
          </div>
        )}
      </section>
    </>
  );
};

export default RecipeIngredientToolTab;
