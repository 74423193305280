import React from "react";
import { useState, useEffect } from "react";

const RecipeSortAlphabetically = (props) => {
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    setRecipes(props.recipes);
  }, []);

  const sortAlphabetically = () => {
    let copy = [...recipes];
    copy.sort((a, b) => a.recipeName.localeCompare(b.recipeName));
    setRecipes(copy);
    props.onAlphaSort(copy);
    props.onSort();
  };

  return (
    <>
      <button
        className="Green p25p2 bord-15 Background-LGreen Box-Shadow"
        onClick={sortAlphabetically}
      >
        Alphabetically
      </button>
    </>
  );
};

export default RecipeSortAlphabetically;
