import React, { useEffect, useState } from "react";
import RecipeIngredientLinkCard from "./RecipeIngredientLinkCard";

const RecipeIngredientLinks = (ingredients) => {
  const [currentIngredients, setCurrentIngredients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!ingredients.recipeIngredients) {
      setCurrentIngredients(ingredients.ingredients);
      setIsLoading(false);
    }
    if (ingredients.ingredients && ingredients.recipeIngredients) {
      let tempIngredients = [];
      ingredients.recipeIngredients.map((ingredient) => {
        ingredients.ingredients.map((i) => {
          if (i.name === ingredient.name && !tempIngredients.includes(i))
            tempIngredients.push(i);
        });
      });
      setCurrentIngredients(tempIngredients);
      setIsLoading(false);
    }
  }, [ingredients]);

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className="Row kitchen-tools">
          {currentIngredients?.map((ingredient) => {
            return (
              <RecipeIngredientLinkCard
                key={ingredient.id}
                ingredient={ingredient}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default RecipeIngredientLinks;
