import React from "react";

const RecipeBadges = (props) => {
  return (
    <>
      <div className="Column">
        <img
          className={
            props.header ? "Width-1-5 marg-right-5" : "P-T-R-L-5 Width-4 Badge"
          }
          alt={props.badge.name}
          src={props.badge.image}
        />
        <p
          className={
            props.header ? "Hidden" : "Small-Font Justify-Center Green"
          }
        >
          {props.badge.name}
        </p>
      </div>
    </>
  );
};

export default RecipeBadges;
