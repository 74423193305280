import { Link } from "react-router-dom";
import { useState } from "react";
import logo from "../../images/BlogFreeRecipe-drkBkgrd.png";
import React from "react";
import RecipeBadges from "./RecipeBadges";

const RecipeCollapsed = (props) => {
  const [mainPicture, setMainPicture] = useState(logo);

  useState(() => {
    if (props.recipe.mainPicture !== null && props.recipe.mainPicture !== "")
      setMainPicture(props.recipe.mainPicture);
    if (
      (props.recipe.mainPicture === null || props.recipe.mainPicture === "") &&
      props.recipe.additionalPictures.length > 0
    ) {
      setMainPicture(props.recipe.additionalPictures[0]);
    }
  }, []);

  return (
    <>
      <div className="Row">
        <img alt="small main" src={mainPicture} className="Small-Logo" />
        <div className="Column Space-B">
          <Link to={`recipeDetails/${props.recipe.id}`}>
            <h5 className=" Width-10 Shrink-Header">
              {props.recipe.recipeName}
            </h5>
          </Link>
          <div className="Row P-R-B-L-5 Div-Parent-Badge">
            {props.badges.map((badge, index) => {
              return (
                <RecipeBadges
                  key={index + "recipe list view badge"}
                  badge={badge}
                  header={true}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipeCollapsed;
