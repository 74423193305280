import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const RecipeSearchByName = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [buttonWidth, setButtonWidth] = useState(0);

  const handleChange = (e) => {
    setSearchInput(e.target.value);
    const filteredRecipes = props.recipes.filter((recipe) => {
      return recipe.recipeName
        .toLowerCase()
        .includes(searchInput.toLowerCase());
    });
    props.onSearch(filteredRecipes);
    props.onSearched();
  };

  const clearSearch = () => {
    setSearchInput("");
    props.onSearch(props.recipes);
  };

  useEffect(() => {
    if (buttonWidth === 0) {
      setButtonWidth(document.getElementById("searchIcon")?.offsetWidth);
    }
  }, [buttonWidth]);

  return (
    <>
      <div>
        <input
          id="Recipe-Search"
          style={{ marginLeft: buttonWidth }}
          className="Search-By-Name"
          type="search"
          placeholder="Search recipes by name"
          value={searchInput}
          onChange={handleChange}
        />
        <button
          id="searchIcon"
          className="faking"
          onClick={searchInput === "" ? () => {} : clearSearch}
        >
          <FontAwesomeIcon
            className="fa-thin"
            icon={searchInput === "" ? faMagnifyingGlass : faXmark}
          />
        </button>
      </div>
    </>
  );
};

export default RecipeSearchByName;
