import React from "react";

const RecipeIngredients = (ingredient) => {
  let amount = ingredient.ingredient.quantity;
  let originalAmt = ingredient.ingredient.quantity;
  switch (amount) {
    case "1/8":
      amount = <span>&#8539;</span>;
      break;
    case "1/4":
      amount = <span>&frac14;</span>;
      break;
    case "1/3":
      amount = <span>&#8531;</span>;
      break;
    case "1/2":
      amount = <span>&frac12;</span>;
      break;
    case "2/3":
      amount = <span>&#8532;</span>;
      break;
    case "3/4":
      amount = <span>&frac34;</span>;
      break;
    case "1 1/4":
      amount = <span>1 &frac14;</span>;
      break;
    case "1 1/3":
      amount = <span>1 &#8531;</span>;
      break;
    case "1 1/2":
      amount = <span>1 &frac12;</span>;
      break;
    case "2 1/2":
      amount = <span>2 &frac12;</span>;
      break;
    case "2 3/4":
      amount = <span>2 &frac34;</span>;
      break;
    default:
      amount = originalAmt;
      break;
  }

  return (
    <>
      <div className="Row Medium-Font">
        <p>-</p>
        <span className="marg-0 marg-left-5">
          {amount} {ingredient.ingredient.measurement}{" "}
          {ingredient.ingredient.amazonLink === undefined ? (
            <>{ingredient.ingredient.name}</>
          ) : (
            <a href={ingredient.ingredient.amazonLink}>
              {ingredient.ingredient.name}
            </a>
          )}{" "}
        </span>
      </div>
    </>
  );
};

export default RecipeIngredients;
