import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import React from "react";

const RecipeProgressBar = (props) => {
  const [currentStep, setCurrentStep] = useState(props.recipe.steps[0]);

  useEffect(() => {
    setCurrentStep(props.recipe.steps[0]);
  }, []);

  const nextStep = () => {
    if (currentStep.id.split("-")[0] < props.recipe.steps.length) {
      let newStep = parseInt(currentStep.id.split("-")[0]) + 1;
      setCurrentStep(props.recipe.steps[newStep - 1]);
    }
  };

  const prevStep = () => {
    if (currentStep.id.split("-")[0] > 1) {
      let newStep = parseInt(currentStep.id.split("-")[0]) - 1;
      setCurrentStep(props.recipe.steps[newStep - 1]);
    }
  };

  return (
    <>
      <div className="pt-1 marg-5 max-h-25 Column">
        <div className="Justify-Center ">
          {props.recipe.steps.length < 11 ? (
            <div className="Row marg-t-1 max-h-1 max-w-15">
              {props.recipe.steps.map((step, index) => {
                return (
                  <div key={index + "step-progress-bar"}>
                    <ul className="p0 Row">
                      <li
                        onClick={() => {
                          setCurrentStep(step);
                        }}
                        className={
                          parseInt(currentStep.id.split("-")[0]) >=
                          parseInt(step.id.split("-")[0])
                            ? "Done"
                            : "To-Do"
                        }
                      >
                        {step.id.split("-")[0]}
                      </li>
                    </ul>
                    {step.id.split("-")[0] < props.recipe.steps.length && (
                      <hr
                        className={
                          step.id.split("-")[0] >
                          currentStep.id.split("-")[0] - 1
                            ? "Progress-Bar-To-Do"
                            : "Progress-Bar-Done"
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="marg-t-1 Width-90">
              <ul id="Progress-Bar-UL Width-100">
                {props.recipe.steps.map((step, index) => {
                  return (
                    <li
                      key={index + "step-progress-bar"}
                      onClick={() => {
                        setCurrentStep(step);
                      }}
                      className={
                        index === 0
                          ? "First-LI"
                          : index === props.recipe.steps.length - 1 &&
                            parseInt(currentStep.id.split("-")[0]) >=
                              parseInt(step.id.split("-")[0])
                          ? "Done Last-LI"
                          : index === props.recipe.steps.length - 1 &&
                            parseInt(currentStep.id.split("-")[0]) <
                              parseInt(step.id.split("-")[0])
                          ? "To-Do Last-LI"
                          : index !== 0 &&
                            props.recipe.steps.length - 1 &&
                            parseInt(currentStep.id.split("-")[0]) >=
                              parseInt(step.id.split("-")[0])
                          ? "Done Block"
                          : index !== 0 &&
                            props.recipe.steps.length - 1 &&
                            parseInt(currentStep.id.split("-")[0]) <
                              parseInt(step.id.split("-")[0]) &&
                            "To-Do Block"
                      }
                      style={{
                        width: 100 / props.recipe.steps.length + "%",
                      }}
                    ></li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <div className="Step-Card-Container Justify-Center">
          {currentStep && (
            <div className="Justify-Center">
              <div className="Row Width-25">
                <button className="Arrow-Left-Btn" onClick={prevStep}>
                  <FontAwesomeIcon className="Arrow-Left" icon={faArrowDown} />
                </button>
                <section className="Step-Card">
                  <h3 className="Justify-Center marg-1">
                    Step {currentStep.id.split("-")[0]}
                  </h3>
                  <div className="Step-Detail p1">
                    <p>{currentStep.detail}</p>
                  </div>
                </section>
                <button className="Arrow-Right-Btn" onClick={nextStep}>
                  <FontAwesomeIcon className="Arrow-Right" icon={faArrowDown} />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RecipeProgressBar;
