import * as Realm from "realm-web";
import React, { useState, createContext, useEffect } from "react";

export const RecipeContext = createContext();

export const RecipeProvider = (props) => {
  const [recipes, setRecipes] = useState([]);
  const [badges, setBadges] = useState([]);
  const [kitchenTools, setKitchenTools] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [featureToggles, setFeatureToggles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const realm_app_id = "data-raewy";
      const app = new Realm.App({ id: realm_app_id });
      const credentials = Realm.Credentials.anonymous();

      try {
        const user = await app.logIn(credentials);
        const allRecipes = await user.functions.getAllRecipes();
        setRecipes(allRecipes);
        const allBadges = await user.functions.getAllBadges();
        setBadges(allBadges);
        const allKitchenTools = await user.functions.getAllKitchenTools();
        setKitchenTools(allKitchenTools);
        const allIngredients = await user.functions.getAllIngredients();
        setIngredients(allIngredients);
        const allFeatureToggles = await user.functions.getFeatureToggleStatus();
        setFeatureToggles(allFeatureToggles);
      } catch (error) {
        console.log("This went wrong: ", error);
      }
    }
    fetchData();
  }, []);

  return (
    <RecipeContext.Provider
      value={{
        recipes,
        badges,
        kitchenTools,
        ingredients,
        featureToggles,
      }}
    >
      {props.children}
    </RecipeContext.Provider>
  );
};
