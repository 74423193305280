import React from "react";
import { useState, useEffect } from "react";

const RecipeSortByNumOfSteps = (props) => {
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    setRecipes(props.recipes);
  }, []);

  const sortByNumberOfSteps = () => {
    let copy = [...recipes];
    copy.sort((a, b) => a.steps.length - b.steps.length);
    setRecipes(copy);
    props.onNumOfStepsSort(copy);
    props.onSort();
  };

  return (
    <>
      <button
        className="Green p25p2 bord-15 Background-LGreen Box-Shadow"
        onClick={sortByNumberOfSteps}
      >
        Recipe Steps
      </button>
    </>
  );
};

export default RecipeSortByNumOfSteps;
